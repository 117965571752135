import { NgModule } from '@angular/core';

import { FilterCollectionPipe } from './filter-collection.pipe';
import { FirstNamePipe } from './first-name.pipe';

@NgModule({
  declarations: [FilterCollectionPipe, FirstNamePipe],
  exports: [FilterCollectionPipe, FirstNamePipe],
})
export class SharedPipesModule {}
